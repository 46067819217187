<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem" style="margin-right: 0" :to="{path: '/order'}">
          <span style="color: #909399;font-weight: normal;">订货单</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item class="breaditem" style="margin-left: 0">
          <span style="color: #909399">{{name}}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv">
      <el-input class="family" size="small" v-model.trim="query.value"
                @keyup.enter.native="selectDetail" clearable maxlength="20"
                style="width: 300px !important;margin-right: 10px">
        <el-select class="family" size="small" slot="prepend" v-model="query.type"
                   @change="typeChange" style="width: 75px">
          <el-option value="isbn" label="书号"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
          <el-option value="title" label="书名"
                     style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
        </el-select>
      </el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="selectDetail">查询</el-button>
    </div>
    <div style="height: 70px">
      <div style="height: 47px"/>
      <div v-if="total>0" style="height: 23px;line-height: 13px">
        <span class="span" style="color: #606266">搜索出 </span>
        <span class="span" style="color: #409EFF">{{total}}</span>
        <span class="span" style="color: #606266"> 条结果</span>
      </div>
    </div>
    <el-table border stripe size="small" :data="details" :row-style="{height:'42px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
              :style="{width: '1000px'}">
      <el-table-column label="书号" prop="book.isbn" width="110" :resizable="false"/>
      <el-table-column label="书名" prop="book.title" width="319" :resizable="false"/>
      <el-table-column label="定价" prop="book.price" width="75" :resizable="false"
                       :formatter="bookPriceFormat"/>
      <el-table-column label="折扣" width="60" :resizable="false">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: column">
            <span v-if="scope.row.oriprice>scope.row.discount"
                  style="color: #C0C4CC;text-decoration: line-through">
                {{discountFormat(scope.row.oriprice)}}
            </span>
            <span>{{discountFormat(scope.row.discount)}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="售价" prop="price" width="75" :resizable="false"
                       :formatter="priceFormat"/>
      <el-table-column label="库位" prop="position" width="120" :resizable="false"/>
      <el-table-column label="订数" prop="orderNum" width="60" :resizable="false"/>
      <el-table-column label="缺货" width="60" :resizable="false">
        <template slot-scope="scope">
          <el-popover
            v-if="!((scope.row.order.getBook && scope.row.order.accountGet)
            || scope.row.order.flag || scope.row.order.account)"
            placement="right" v-model="scope.row.lessVisible">
            <div style="display: flex;justify-content: center;align-items: center">
              <el-input-number class="family1" v-model="scope.row.less" size="mini"
                               :min="0" :max="scope.row.orderNum-scope.row.backNum"/>
              <el-button class="buttonPopo" size="mini" type="success"
                         @click="updLessNum(scope.row)"
                         icon="el-icon-check" :loading="scope.row.lessLoading"/>
            </div>
            <el-link class="link" slot="reference" :underline="false"
                     @click="initLessNum(scope.row)"
                     :style="{color:scope.row.lessNum===0?'#C0C4CC':'#606266'}">
              {{scope.row.lessNum===0?'设置':scope.row.lessNum}}
            </el-link>
          </el-popover>
          <span
            v-if="(scope.row.order.getBook && scope.row.order.accountGet)
            || scope.row.order.flag || scope.row.order.account">
            {{lessNumFormat(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="退货" prop="backNum" width="60"
                       :resizable="false" :formatter="backNumFormat">
      </el-table-column>
      <el-table-column label="加购人" prop="user" width="60" :resizable="false"/>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
  </div>
</template>

<script>
  export default {
    created () {
      this.query.orderId = sessionStorage.getItem('id')
      this.query.type = sessionStorage.getItem('type')
      this.query.value = sessionStorage.getItem('value')
      this.name = sessionStorage.getItem('name')
      if (this.query.type === null) {
        this.query.type = 'isbn'
      }
      if (this.query.orderId === null) {
        this.name = '订单明细'
      } else {
        this.name = '订单明细：' + this.name + ' ▪ #' + (Array(7).join('0') + this.query.orderId).slice(-7)
      }
      this.getDetailList()
    },
    data () {
      return {
        query: {
          orderId: null,
          type: '',
          value: '',
          page: 1
        },
        details: [],
        total: 0,
        name: ''
      }
    },
    methods: {
      bookPriceFormat (row) {
        if (row.book.price > 0) {
          return parseFloat(row.book.price).toFixed(2)
        }
      },
      discountFormat (value) {
        if (value !== undefined) {
          return parseFloat(value).toFixed(2)
        }
      },
      priceFormat (row) {
        return parseFloat(row.price).toFixed(2)
      },
      lessNumFormat (row) {
        if (row.lessNum > 0) {
          return row.lessNum
        }
      },
      backNumFormat (row) {
        if (row.backNum > 0) {
          return row.backNum
        }
      },
      typeChange () {
        this.query.value = ''
      },
      initLessNum (row) {
        row.less = row.lessNum
      },

      selectDetail () {
        this.query.page = 1
        this.getDetailList()
      },
      pageChange (page) {
        this.query.page = page
        this.getDetailList()
      },
      async getDetailList () {
        if (this.query.orderId === null) {
          return
        }

        const loading = this.loading()
        try {
          const { data: res } = await this.$http.get('getDetailList', { params: this.query })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.details = []
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.details = res.data.data
          this.details = this.details.map(item => {
            this.$set(item, 'less', 0)
            this.$set(item, 'lessVisible', false)
            this.$set(item, 'lessLoading', false)
            return item
          })
          loading.close()
          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.details = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async updLessNum (row) {
        if (row.less === undefined) {
          this.$msg.warning('请输入缺货数量！')
          return
        }
        this.$set(row, 'lessVisible', false)
        this.$set(row, 'lessLoading', true)

        try {
          const { data: res } = await this.$http.post('updDetail',
            {
              orderId: row.order.id,
              bookId: row.book.id,
              lessNum: row.less
            })
          this.$set(row, 'lessLoading', false)
          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
          } else {
            this.$set(row, 'lessNum', row.less)
            this.$msg.success('设置成功！')
          }
        } catch (e) {
          this.$set(row, 'lessLoading', false)
          this.$msg.error(this.netError)
        }
      }
    }
  }
</script>

<style scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .family1 input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .spanPopo {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  .el-input-number {
    width: 100px !important;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }
</style>
